import React from 'react';
import { Box } from '@mui/material';

import Table from './components/Table';

const App = () => {
  return (
    <>
      <Box width="100%" height="100vh">
        <Table />
      </Box>
    </>
  );
};

export default App;
