import { GridColDef } from '@mui/x-data-grid';

import { numberFormatter } from './helpers';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Country or Territory',
    width: 200,
  },
  {
    field: 'population',
    headerName: 'Population',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'publishersPeak',
    headerName: '2022 Peak Pubs.',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'ratio',
    headerName: 'Ratio, 1 Publisher to',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'publishersAverage',
    headerName: '2022 Av. Pubs.',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'increase',
    headerName: '% Inc. Over 2021',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'baptized',
    headerName: '2022 No. Bptzd.',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'pioneersAverage',
    headerName: 'Av. Pio. Pubs.',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'congregations',
    headerName: 'No. of Congs.',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'studies',
    headerName: 'Av. Bible Studies',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
  {
    field: 'memorialAttendance',
    headerName: 'Memorial Attendance',
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: numberFormatter,
  },
];
