import { Country } from '../types';

export const data: Country[] = [
  {
    name: 'American Samoa',
    population: 55000,
    publishersPeak: 154,
    ratio: 420,
    publishersAverage: 131,
    increase: -9,
    baptized: null,
    pioneersAverage: 32,
    congregations: 3,
    studies: 202,
    memorialAttendance: 647,
  },
  {
    name: 'Andorra',
    population: 77000,
    publishersPeak: 169,
    ratio: 461,
    publishersAverage: 167,
    increase: -1,
    baptized: null,
    pioneersAverage: 23,
    congregations: 3,
    studies: 92,
    memorialAttendance: 331,
  },
  {
    name: 'Angola',
    population: 35027000,
    publishersPeak: 159384,
    ratio: 226,
    publishersAverage: 154953,
    increase: 3,
    baptized: 3273,
    pioneersAverage: 25609,
    congregations: 2539,
    studies: 247508,
    memorialAttendance: 485484,
  },
  {
    name: 'Anguilla',
    population: 15000,
    publishersPeak: 67,
    ratio: 259,
    publishersAverage: 58,
    increase: 5,
    baptized: null,
    pioneersAverage: 11,
    congregations: 1,
    studies: 52,
    memorialAttendance: 208,
  },
  {
    name: 'Antigua',
    population: 100000,
    publishersPeak: 478,
    ratio: 215,
    publishersAverage: 466,
    increase: 1,
    baptized: null,
    pioneersAverage: 41,
    congregations: 7,
    studies: 332,
    memorialAttendance: 1244,
  },
  {
    name: 'Argentina',
    population: 47327000,
    publishersPeak: 155443,
    ratio: 308,
    publishersAverage: 153787,
    increase: -2,
    baptized: 2869,
    pioneersAverage: 35977,
    congregations: 1945,
    studies: 100541,
    memorialAttendance: 345669,
  },
  {
    name: 'Armenia',
    population: 2964000,
    publishersPeak: 11191,
    ratio: 267,
    publishersAverage: 11093,
    increase: -1,
    baptized: 192,
    pioneersAverage: 2259,
    congregations: 135,
    studies: 3903,
    memorialAttendance: 26543,
  },
  {
    name: 'Aruba',
    population: 108000,
    publishersPeak: 1127,
    ratio: 98,
    publishersAverage: 1100,
    increase: -1,
    baptized: 15,
    pioneersAverage: 139,
    congregations: 14,
    studies: 695,
    memorialAttendance: 2978,
  },
  {
    name: 'Australia',
    population: 26069000,
    publishersPeak: 71355,
    ratio: 369,
    publishersAverage: 70663,
    increase: 1,
    baptized: 1047,
    pioneersAverage: 9485,
    congregations: 739,
    studies: 20388,
    memorialAttendance: 113915,
  },
  {
    name: 'Austria',
    population: 8979000,
    publishersPeak: 21966,
    ratio: 412,
    publishersAverage: 21788,
    increase: 1,
    baptized: 231,
    pioneersAverage: 2209,
    congregations: 287,
    studies: 6804,
    memorialAttendance: 34282,
  },
  {
    name: 'Azerbaijan',
    population: 10156000,
    publishersPeak: 1674,
    ratio: 6118,
    publishersAverage: 1660,
    increase: 2,
    baptized: 77,
    pioneersAverage: 451,
    congregations: 18,
    studies: 2551,
    memorialAttendance: 3647,
  },
  {
    name: 'Azores',
    population: 236000,
    publishersPeak: 802,
    ratio: 298,
    publishersAverage: 791,
    increase: -1,
    baptized: 14,
    pioneersAverage: 131,
    congregations: 15,
    studies: 465,
    memorialAttendance: 1685,
  },
  {
    name: 'Bahamas',
    population: 401000,
    publishersPeak: 1868,
    ratio: 231,
    publishersAverage: 1737,
    increase: -1,
    baptized: 28,
    pioneersAverage: 277,
    congregations: 28,
    studies: 1686,
    memorialAttendance: 4351,
  },
  {
    name: 'Bangladesh',
    population: 170298000,
    publishersPeak: 338,
    ratio: 509874,
    publishersAverage: 334,
    increase: 2,
    baptized: 7,
    pioneersAverage: 140,
    congregations: 6,
    studies: 481,
    memorialAttendance: 1048,
  },
  {
    name: 'Barbados',
    population: 288000,
    publishersPeak: 2389,
    ratio: 122,
    publishersAverage: 2360,
    increase: -1,
    baptized: 38,
    pioneersAverage: 191,
    congregations: 30,
    studies: 2016,
    memorialAttendance: 6732,
  },
  {
    name: 'Belarus',
    population: 9433000,
    publishersPeak: 6167,
    ratio: 1546,
    publishersAverage: 6100,
    increase: -1,
    baptized: 125,
    pioneersAverage: 1475,
    congregations: 83,
    studies: 4516,
    memorialAttendance: 11784,
  },
  {
    name: 'Belgium',
    population: 11584000,
    publishersPeak: 25970,
    ratio: 448,
    publishersAverage: 25835,
    increase: null,
    baptized: 334,
    pioneersAverage: 2441,
    congregations: 336,
    studies: 10646,
    memorialAttendance: 44337,
  },
  {
    name: 'Belize',
    population: 441000,
    publishersPeak: 2614,
    ratio: 174,
    publishersAverage: 2539,
    increase: -1,
    baptized: 49,
    pioneersAverage: 487,
    congregations: 54,
    studies: 2066,
    memorialAttendance: 8317,
  },
  {
    name: 'Benin',
    population: 12782000,
    publishersPeak: 14202,
    ratio: 953,
    publishersAverage: 13410,
    increase: 2,
    baptized: 380,
    pioneersAverage: 1841,
    congregations: 246,
    studies: 17068,
    memorialAttendance: 38932,
  },
  {
    name: 'Bermuda',
    population: 62000,
    publishersPeak: 443,
    ratio: 159,
    publishersAverage: 389,
    increase: -3,
    baptized: null,
    pioneersAverage: 73,
    congregations: 5,
    studies: 172,
    memorialAttendance: 963,
  },
  {
    name: 'Bolivia',
    population: 11993000,
    publishersPeak: 29268,
    ratio: 411,
    publishersAverage: 29147,
    increase: -1,
    baptized: 559,
    pioneersAverage: 8269,
    congregations: 448,
    studies: 25747,
    memorialAttendance: 83177,
  },
  {
    name: 'Bonaire',
    population: 22000,
    publishersPeak: 138,
    ratio: 183,
    publishersAverage: 120,
    increase: 3,
    baptized: null,
    pioneersAverage: 25,
    congregations: 2,
    studies: 71,
    memorialAttendance: 352,
  },
  {
    name: 'Bosnia and Herzegovina',
    population: 3249000,
    publishersPeak: 1011,
    ratio: 3285,
    publishersAverage: 989,
    increase: -4,
    baptized: 8,
    pioneersAverage: 188,
    congregations: 16,
    studies: 382,
    memorialAttendance: 1574,
  },
  {
    name: 'Botswana',
    population: 2346000,
    publishersPeak: 2345,
    ratio: 1026,
    publishersAverage: 2286,
    increase: 1,
    baptized: 73,
    pioneersAverage: 344,
    congregations: 43,
    studies: 3000,
    memorialAttendance: 5913,
  },
  {
    name: 'Brazil',
    population: 214886000,
    publishersPeak: 909879,
    ratio: 238,
    publishersAverage: 902121,
    increase: null,
    baptized: 15338,
    pioneersAverage: 173032,
    congregations: 12439,
    studies: 709982,
    memorialAttendance: 1808720,
  },
  {
    name: 'Britain',
    population: 65954000,
    publishersPeak: 139960,
    ratio: 474,
    publishersAverage: 139106,
    increase: 1,
    baptized: 1665,
    pioneersAverage: 19696,
    congregations: 1614,
    studies: 42225,
    memorialAttendance: 220438,
  },
  {
    name: 'Bulgaria',
    population: 6845000,
    publishersPeak: 2724,
    ratio: 2558,
    publishersAverage: 2676,
    increase: 1,
    baptized: 83,
    pioneersAverage: 725,
    congregations: 58,
    studies: 1600,
    memorialAttendance: 5958,
  },
  {
    name: 'Burkina Faso',
    population: 22103000,
    publishersPeak: 1944,
    ratio: 12813,
    publishersAverage: 1725,
    increase: 1,
    baptized: 36,
    pioneersAverage: 232,
    congregations: 48,
    studies: 1784,
    memorialAttendance: 4251,
  },
  {
    name: 'Burundi',
    population: 12625000,
    publishersPeak: 17019,
    ratio: 767,
    publishersAverage: 16460,
    increase: 5,
    baptized: 575,
    pioneersAverage: 2585,
    congregations: 281,
    studies: 24765,
    memorialAttendance: 53130,
  },
  {
    name: 'Cambodia',
    population: 16681000,
    publishersPeak: 1125,
    ratio: 15082,
    publishersAverage: 1106,
    increase: -2,
    baptized: 32,
    pioneersAverage: 554,
    congregations: 17,
    studies: 945,
    memorialAttendance: 2047,
  },
  {
    name: 'Cameroon',
    population: 27912000,
    publishersPeak: 43217,
    ratio: 660,
    publishersAverage: 42315,
    increase: 1,
    baptized: 791,
    pioneersAverage: 5863,
    congregations: 499,
    studies: 32287,
    memorialAttendance: 89171,
  },
  {
    name: 'Canada',
    population: 38388000,
    publishersPeak: 118607,
    ratio: 325,
    publishersAverage: 118004,
    increase: null,
    baptized: 1399,
    pioneersAverage: 16980,
    congregations: 1177,
    studies: 44174,
    memorialAttendance: 188701,
  },
  {
    name: 'Cape Verde',
    population: 568000,
    publishersPeak: 2296,
    ratio: 251,
    publishersAverage: 2265,
    increase: null,
    baptized: 52,
    pioneersAverage: 397,
    congregations: 35,
    studies: 2222,
    memorialAttendance: 7336,
  },
  {
    name: 'Cayman Islands',
    population: 67000,
    publishersPeak: 280,
    ratio: 251,
    publishersAverage: 267,
    increase: -1,
    baptized: null,
    pioneersAverage: 41,
    congregations: 3,
    studies: 147,
    memorialAttendance: 638,
  },
  {
    name: 'Central African Republic',
    population: 5017000,
    publishersPeak: 2661,
    ratio: 2053,
    publishersAverage: 2444,
    increase: 4,
    baptized: 61,
    pioneersAverage: 265,
    congregations: 65,
    studies: 4021,
    memorialAttendance: 16500,
  },
  {
    name: 'Chad',
    population: 17414000,
    publishersPeak: 806,
    ratio: 22734,
    publishersAverage: 766,
    increase: -1,
    baptized: 30,
    pioneersAverage: 93,
    congregations: 22,
    studies: 866,
    memorialAttendance: 3501,
  },
  {
    name: 'Chile',
    population: 19829000,
    publishersPeak: 86746,
    ratio: 230,
    publishersAverage: 86316,
    increase: null,
    baptized: 1301,
    pioneersAverage: 20069,
    congregations: 955,
    studies: 44009,
    memorialAttendance: 218637,
  },
  {
    name: 'Chuuk',
    population: 54000,
    publishersPeak: 32,
    ratio: 2000,
    publishersAverage: 27,
    increase: -13,
    baptized: null,
    pioneersAverage: 8,
    congregations: 2,
    studies: 61,
    memorialAttendance: 180,
  },
  {
    name: 'Colombia',
    population: 51513000,
    publishersPeak: 188219,
    ratio: 278,
    publishersAverage: 185586,
    increase: -2,
    baptized: 3187,
    pioneersAverage: 31715,
    congregations: 2267,
    studies: 142917,
    memorialAttendance: 568136,
  },
  {
    name: 'Congo, Dem. Republic of',
    population: 95241000,
    publishersPeak: 223678,
    ratio: 437,
    publishersAverage: 217977,
    increase: 8,
    baptized: 5173,
    pioneersAverage: 20239,
    congregations: 4037,
    studies: 335763,
    memorialAttendance: 1107590,
  },
  {
    name: 'Congo, Republic of',
    population: 5798000,
    publishersPeak: 8556,
    ratio: 707,
    publishersAverage: 8200,
    increase: 2,
    baptized: 194,
    pioneersAverage: 1250,
    congregations: 116,
    studies: 12653,
    memorialAttendance: 25093,
  },
  {
    name: 'Cook Islands',
    population: 18000,
    publishersPeak: 211,
    ratio: 90,
    publishersAverage: 199,
    increase: 1,
    baptized: 2,
    pioneersAverage: 27,
    congregations: 3,
    studies: 122,
    memorialAttendance: 500,
  },
  {
    name: 'Costa Rica',
    population: 5213000,
    publishersPeak: 32504,
    ratio: 161,
    publishersAverage: 32290,
    increase: null,
    baptized: 546,
    pioneersAverage: 4275,
    congregations: 435,
    studies: 23398,
    memorialAttendance: 72360,
  },
  {
    name: 'Côte d’Ivoire',
    population: 29389000,
    publishersPeak: 11877,
    ratio: 2521,
    publishersAverage: 11657,
    increase: null,
    baptized: 255,
    pioneersAverage: 1577,
    congregations: 231,
    studies: 19033,
    memorialAttendance: 37361,
  },
  {
    name: 'Croatia',
    population: 4069000,
    publishersPeak: 4736,
    ratio: 867,
    publishersAverage: 4693,
    increase: -2,
    baptized: 40,
    pioneersAverage: 462,
    congregations: 57,
    studies: 1123,
    memorialAttendance: 7218,
  },
  {
    name: 'Cuba',
    population: 11113000,
    publishersPeak: 91710,
    ratio: 123,
    publishersAverage: 90315,
    increase: -3,
    baptized: 1216,
    pioneersAverage: 8932,
    congregations: 1399,
    studies: 38669,
    memorialAttendance: 166891,
  },
  {
    name: 'Curaçao',
    population: 166000,
    publishersPeak: 2040,
    ratio: 83,
    publishersAverage: 1991,
    increase: -1,
    baptized: 40,
    pioneersAverage: 254,
    congregations: 25,
    studies: 1420,
    memorialAttendance: 5629,
  },
  {
    name: 'Cyprus',
    population: 1224000,
    publishersPeak: 2960,
    ratio: 421,
    publishersAverage: 2908,
    increase: 3,
    baptized: 28,
    pioneersAverage: 571,
    congregations: 40,
    studies: 1355,
    memorialAttendance: 5178,
  },
  {
    name: 'Czech Republic',
    population: 10520000,
    publishersPeak: 15957,
    ratio: 678,
    publishersAverage: 15518,
    increase: 1,
    baptized: 143,
    pioneersAverage: 1567,
    congregations: 212,
    studies: 5541,
    memorialAttendance: 29183,
  },
  {
    name: 'Denmark',
    population: 5884000,
    publishersPeak: 14534,
    ratio: 407,
    publishersAverage: 14470,
    increase: null,
    baptized: 129,
    pioneersAverage: 1316,
    congregations: 174,
    studies: 3906,
    memorialAttendance: 20688,
  },
  {
    name: 'Dominica',
    population: 76000,
    publishersPeak: 439,
    ratio: 189,
    publishersAverage: 403,
    increase: -1,
    baptized: null,
    pioneersAverage: 56,
    congregations: 10,
    studies: 408,
    memorialAttendance: 1215,
  },
  {
    name: 'Dominican Republic',
    population: 11056000,
    publishersPeak: 40149,
    ratio: 290,
    publishersAverage: 38131,
    increase: -1,
    baptized: 578,
    pioneersAverage: 7580,
    congregations: 539,
    studies: 41581,
    memorialAttendance: 115138,
  },
  {
    name: 'Ecuador',
    population: 18113000,
    publishersPeak: 100090,
    ratio: 182,
    publishersAverage: 99399,
    increase: -1,
    baptized: 1908,
    pioneersAverage: 21404,
    congregations: 1204,
    studies: 84545,
    memorialAttendance: 294770,
  },
  {
    name: 'El Salvador',
    population: 6550000,
    publishersPeak: 38717,
    ratio: 172,
    publishersAverage: 38016,
    increase: -2,
    baptized: 407,
    pioneersAverage: 5043,
    congregations: 654,
    studies: 19313,
    memorialAttendance: 84039,
  },
  {
    name: 'Equatorial Guinea',
    population: 1497000,
    publishersPeak: 2411,
    ratio: 643,
    publishersAverage: 2327,
    increase: -3,
    baptized: 68,
    pioneersAverage: 312,
    congregations: 31,
    studies: 3554,
    memorialAttendance: 6803,
  },
  {
    name: 'Estonia',
    population: 1331000,
    publishersPeak: 4018,
    ratio: 335,
    publishersAverage: 3976,
    increase: null,
    baptized: 48,
    pioneersAverage: 643,
    congregations: 55,
    studies: 1732,
    memorialAttendance: 6853,
  },
  {
    name: 'Eswatini',
    population: 1185000,
    publishersPeak: 3305,
    ratio: 372,
    publishersAverage: 3186,
    increase: null,
    baptized: 64,
    pioneersAverage: 371,
    congregations: 76,
    studies: 3241,
    memorialAttendance: 7896,
  },
  {
    name: 'Ethiopia',
    population: 120813000,
    publishersPeak: 11074,
    ratio: 11046,
    publishersAverage: 10937,
    increase: 1,
    baptized: 218,
    pioneersAverage: 2391,
    congregations: 183,
    studies: 10712,
    memorialAttendance: 24477,
  },
  {
    name: 'Falkland Islands',
    population: 4000,
    publishersPeak: 16,
    ratio: 250,
    publishersAverage: 16,
    increase: 14,
    baptized: null,
    pioneersAverage: 5,
    congregations: 1,
    studies: 12,
    memorialAttendance: 20,
  },
  {
    name: 'Faroe Islands',
    population: 54000,
    publishersPeak: 135,
    ratio: 412,
    publishersAverage: 131,
    increase: 2,
    baptized: 1,
    pioneersAverage: 27,
    congregations: 4,
    studies: 57,
    memorialAttendance: 184,
  },
  {
    name: 'Fiji',
    population: 909000,
    publishersPeak: 3186,
    ratio: 301,
    publishersAverage: 3023,
    increase: -2,
    baptized: 95,
    pioneersAverage: 513,
    congregations: 62,
    studies: 3205,
    memorialAttendance: 12452,
  },
  {
    name: 'Finland',
    population: 5548000,
    publishersPeak: 18108,
    ratio: 307,
    publishersAverage: 18068,
    increase: null,
    baptized: 155,
    pioneersAverage: 2429,
    congregations: 275,
    studies: 9689,
    memorialAttendance: 26435,
  },
  {
    name: 'France',
    population: 64663000,
    publishersPeak: 136419,
    ratio: 476,
    publishersAverage: 135723,
    increase: 1,
    baptized: 2026,
    pioneersAverage: 20092,
    congregations: 1477,
    studies: 47209,
    memorialAttendance: 228072,
  },
  {
    name: 'French Guiana',
    population: 305000,
    publishersPeak: 2933,
    ratio: 105,
    publishersAverage: 2892,
    increase: -2,
    baptized: 66,
    pioneersAverage: 563,
    congregations: 45,
    studies: 3908,
    memorialAttendance: 10154,
  },
  {
    name: 'Gabon',
    population: 2332000,
    publishersPeak: 4460,
    ratio: 535,
    publishersAverage: 4356,
    increase: null,
    baptized: 108,
    pioneersAverage: 592,
    congregations: 59,
    studies: 4784,
    memorialAttendance: 11657,
  },
  {
    name: 'Gambia',
    population: 2558000,
    publishersPeak: 280,
    ratio: 9545,
    publishersAverage: 268,
    increase: 3,
    baptized: 7,
    pioneersAverage: 44,
    congregations: 5,
    studies: 339,
    memorialAttendance: 582,
  },
  {
    name: 'Georgia',
    population: 3689000,
    publishersPeak: 18782,
    ratio: 198,
    publishersAverage: 18653,
    increase: 1,
    baptized: 278,
    pioneersAverage: 3695,
    congregations: 224,
    studies: 4677,
    memorialAttendance: 34969,
  },
  {
    name: 'Germany',
    population: 83237000,
    publishersPeak: 170491,
    ratio: 498,
    publishersAverage: 167230,
    increase: 1,
    baptized: 2209,
    pioneersAverage: 18808,
    congregations: 2020,
    studies: 47374,
    memorialAttendance: 267452,
  },
  {
    name: 'Ghana',
    population: 32395000,
    publishersPeak: 149275,
    ratio: 220,
    publishersAverage: 147047,
    increase: null,
    baptized: 4221,
    pioneersAverage: 16131,
    congregations: 2396,
    studies: 245305,
    memorialAttendance: 322892,
  },
  {
    name: 'Gibraltar',
    population: 34000,
    publishersPeak: 159,
    ratio: 248,
    publishersAverage: 137,
    increase: 12,
    baptized: null,
    pioneersAverage: 23,
    congregations: 2,
    studies: 25,
    memorialAttendance: 179,
  },
  {
    name: 'Greece',
    population: 10432000,
    publishersPeak: 27995,
    ratio: 374,
    publishersAverage: 27887,
    increase: -1,
    baptized: 420,
    pioneersAverage: 4658,
    congregations: 354,
    studies: 8041,
    memorialAttendance: 43608,
  },
  {
    name: 'Greenland',
    population: 57000,
    publishersPeak: 129,
    ratio: 456,
    publishersAverage: 125,
    increase: -8,
    baptized: 2,
    pioneersAverage: 32,
    congregations: 5,
    studies: 73,
    memorialAttendance: 297,
  },
  {
    name: 'Grenada',
    population: 113000,
    publishersPeak: 553,
    ratio: 214,
    publishersAverage: 528,
    increase: null,
    baptized: 10,
    pioneersAverage: 80,
    congregations: 9,
    studies: 455,
    memorialAttendance: 1470,
  },
  {
    name: 'Guadeloupe',
    population: 396000,
    publishersPeak: 8534,
    ratio: 48,
    publishersAverage: 8323,
    increase: 1,
    baptized: 118,
    pioneersAverage: 951,
    congregations: 118,
    studies: 6211,
    memorialAttendance: 20016,
  },
  {
    name: 'Guam',
    population: 172000,
    publishersPeak: 742,
    ratio: 236,
    publishersAverage: 730,
    increase: -3,
    baptized: 14,
    pioneersAverage: 181,
    congregations: 9,
    studies: 571,
    memorialAttendance: 1892,
  },
  {
    name: 'Guatemala',
    population: 18584000,
    publishersPeak: 39452,
    ratio: 477,
    publishersAverage: 38974,
    increase: -2,
    baptized: 512,
    pioneersAverage: 6614,
    congregations: 852,
    studies: 23370,
    memorialAttendance: 98300,
  },
  {
    name: 'Guinea',
    population: 13866000,
    publishersPeak: 1150,
    ratio: 12663,
    publishersAverage: 1095,
    increase: 1,
    baptized: 36,
    pioneersAverage: 156,
    congregations: 25,
    studies: 1844,
    memorialAttendance: 3913,
  },
  {
    name: 'Guinea-Bissau',
    population: 2063000,
    publishersPeak: 207,
    ratio: 10634,
    publishersAverage: 194,
    increase: -3,
    baptized: 8,
    pioneersAverage: 49,
    congregations: 4,
    studies: 443,
    memorialAttendance: 592,
  },
  {
    name: 'Guyana',
    population: 794000,
    publishersPeak: 3337,
    ratio: 242,
    publishersAverage: 3284,
    increase: -1,
    baptized: 53,
    pioneersAverage: 532,
    congregations: 47,
    studies: 3447,
    memorialAttendance: 12471,
  },
  {
    name: 'Haiti',
    population: 11680000,
    publishersPeak: 18234,
    ratio: 657,
    publishersAverage: 17774,
    increase: -5,
    baptized: 281,
    pioneersAverage: 2556,
    congregations: 271,
    studies: 20714,
    memorialAttendance: 64155,
  },
  {
    name: 'Honduras',
    population: 9611000,
    publishersPeak: 22324,
    ratio: 439,
    publishersAverage: 21891,
    increase: -3,
    baptized: 253,
    pioneersAverage: 4234,
    congregations: 429,
    studies: 15131,
    memorialAttendance: 60259,
  },
  {
    name: 'Hong Kong',
    population: 7292000,
    publishersPeak: 5510,
    ratio: 1331,
    publishersAverage: 5477,
    increase: -2,
    baptized: 147,
    pioneersAverage: 1361,
    congregations: 66,
    studies: 4405,
    memorialAttendance: 13484,
  },
  {
    name: 'Hungary',
    population: 9689000,
    publishersPeak: 21180,
    ratio: 462,
    publishersAverage: 20959,
    increase: 1,
    baptized: 314,
    pioneersAverage: 2070,
    congregations: 288,
    studies: 6863,
    memorialAttendance: 39452,
  },
  {
    name: 'Iceland',
    population: 377000,
    publishersPeak: 399,
    ratio: 964,
    publishersAverage: 391,
    increase: 2,
    baptized: 9,
    pioneersAverage: 63,
    congregations: 7,
    studies: 185,
    memorialAttendance: 686,
  },
  {
    name: 'India',
    population: 1406632000,
    publishersPeak: 56747,
    ratio: 25038,
    publishersAverage: 56181,
    increase: 3,
    baptized: 2817,
    pioneersAverage: 9634,
    congregations: 947,
    studies: 52177,
    memorialAttendance: 152011,
  },
  {
    name: 'Indonesia',
    population: 279135000,
    publishersPeak: 30306,
    ratio: 9345,
    publishersAverage: 29869,
    increase: 1,
    baptized: 716,
    pioneersAverage: 5721,
    congregations: 476,
    studies: 19773,
    memorialAttendance: 60144,
  },
  {
    name: 'Ireland',
    population: 8859000,
    publishersPeak: 7533,
    ratio: 1207,
    publishersAverage: 7341,
    increase: 2,
    baptized: 126,
    pioneersAverage: 1465,
    congregations: 121,
    studies: 2519,
    memorialAttendance: 12561,
  },
  {
    name: 'Israel',
    population: 9676000,
    publishersPeak: 2067,
    ratio: 4797,
    publishersAverage: 2017,
    increase: 1,
    baptized: 28,
    pioneersAverage: 395,
    congregations: 33,
    studies: 1214,
    memorialAttendance: 3670,
  },
  {
    name: 'Italy',
    population: 60263000,
    publishersPeak: 250624,
    ratio: 241,
    publishersAverage: 250116,
    increase: null,
    baptized: 3181,
    pioneersAverage: 47826,
    congregations: 2843,
    studies: 90566,
    memorialAttendance: 426019,
  },
  {
    name: 'Jamaica',
    population: 2985000,
    publishersPeak: 11124,
    ratio: 274,
    publishersAverage: 10906,
    increase: -1,
    baptized: 169,
    pioneersAverage: 1527,
    congregations: 152,
    studies: 8236,
    memorialAttendance: 35992,
  },
  {
    name: 'Japan',
    population: 125194000,
    publishersPeak: 214359,
    ratio: 585,
    publishersAverage: 213828,
    increase: null,
    baptized: 1858,
    pioneersAverage: 69606,
    congregations: 2893,
    studies: 74891,
    memorialAttendance: 310692,
  },
  {
    name: 'Kazakhstan',
    population: 19205000,
    publishersPeak: 17259,
    ratio: 1122,
    publishersAverage: 17124,
    increase: -1,
    baptized: 283,
    pioneersAverage: 4500,
    congregations: 232,
    studies: 8070,
    memorialAttendance: 30324,
  },
  {
    name: 'Kenya',
    population: 56215000,
    publishersPeak: 29534,
    ratio: 1990,
    publishersAverage: 28243,
    increase: 2,
    baptized: 536,
    pioneersAverage: 3578,
    congregations: 623,
    studies: 27036,
    memorialAttendance: 68579,
  },
  {
    name: 'Kiribati',
    population: 123000,
    publishersPeak: 91,
    ratio: 1482,
    publishersAverage: 83,
    increase: -17,
    baptized: 6,
    pioneersAverage: 24,
    congregations: 2,
    studies: 87,
    memorialAttendance: 441,
  },
  {
    name: 'Korea, Republic of',
    population: 51574000,
    publishersPeak: 105977,
    ratio: 488,
    publishersAverage: 105629,
    increase: 1,
    baptized: 1169,
    pioneersAverage: 50279,
    congregations: 1261,
    studies: 36678,
    memorialAttendance: 138655,
  },
  {
    name: 'Kosovo',
    population: 1795000,
    publishersPeak: 244,
    ratio: 7479,
    publishersAverage: 240,
    increase: -2,
    baptized: 4,
    pioneersAverage: 107,
    congregations: 8,
    studies: 257,
    memorialAttendance: 491,
  },
  {
    name: 'Kosrae',
    population: 8000,
    publishersPeak: 13,
    ratio: 1000,
    publishersAverage: 8,
    increase: -33,
    baptized: null,
    pioneersAverage: 4,
    congregations: 1,
    studies: 12,
    memorialAttendance: 56,
  },
  {
    name: 'Kyrgyzstan',
    population: 6747000,
    publishersPeak: 5097,
    ratio: 1336,
    publishersAverage: 5050,
    increase: -3,
    baptized: 87,
    pioneersAverage: 1372,
    congregations: 89,
    studies: 2480,
    memorialAttendance: 10054,
  },
  {
    name: 'Latvia',
    population: 1893000,
    publishersPeak: 2101,
    ratio: 916,
    publishersAverage: 2067,
    increase: -1,
    baptized: 27,
    pioneersAverage: 367,
    congregations: 30,
    studies: 1140,
    memorialAttendance: 3537,
  },
  {
    name: 'Lesotho',
    population: 2176000,
    publishersPeak: 3729,
    ratio: 601,
    publishersAverage: 3619,
    increase: -1,
    baptized: 103,
    pioneersAverage: 545,
    congregations: 82,
    studies: 2991,
    memorialAttendance: 8348,
  },
  {
    name: 'Liberia',
    population: 5305000,
    publishersPeak: 7391,
    ratio: 792,
    publishersAverage: 6702,
    increase: null,
    baptized: 235,
    pioneersAverage: 771,
    congregations: 141,
    studies: 14351,
    memorialAttendance: 28935,
  },
  {
    name: 'Liechtenstein',
    population: 39000,
    publishersPeak: 94,
    ratio: 429,
    publishersAverage: 91,
    increase: 5,
    baptized: null,
    pioneersAverage: 7,
    congregations: 1,
    studies: 26,
    memorialAttendance: 157,
  },
  {
    name: 'Lithuania',
    population: 2806000,
    publishersPeak: 2924,
    ratio: 982,
    publishersAverage: 2857,
    increase: null,
    baptized: 40,
    pioneersAverage: 443,
    congregations: 40,
    studies: 1194,
    memorialAttendance: 4895,
  },
  {
    name: 'Luxembourg',
    population: 645000,
    publishersPeak: 2188,
    ratio: 297,
    publishersAverage: 2170,
    increase: -1,
    baptized: null,
    pioneersAverage: 254,
    congregations: 30,
    studies: 945,
    memorialAttendance: 4054,
  },
  {
    name: 'Macao',
    population: 677000,
    publishersPeak: 404,
    ratio: 1718,
    publishersAverage: 394,
    increase: -2,
    baptized: 18,
    pioneersAverage: 128,
    congregations: 6,
    studies: 529,
    memorialAttendance: 1122,
  },
  {
    name: 'Madagascar',
    population: 29178000,
    publishersPeak: 35748,
    ratio: 863,
    publishersAverage: 33800,
    increase: 2,
    baptized: 975,
    pioneersAverage: 6548,
    congregations: 826,
    studies: 51947,
    memorialAttendance: 129313,
  },
  {
    name: 'Madeira',
    population: 251000,
    publishersPeak: 1203,
    ratio: 212,
    publishersAverage: 1185,
    increase: 1,
    baptized: 20,
    pioneersAverage: 141,
    congregations: 19,
    studies: 310,
    memorialAttendance: 1829,
  },
  {
    name: 'Malawi',
    population: 20181000,
    publishersPeak: 108044,
    ratio: 220,
    publishersAverage: 91694,
    increase: 3,
    baptized: 2823,
    pioneersAverage: 7827,
    congregations: 1831,
    studies: 84846,
    memorialAttendance: 305182,
  },
  {
    name: 'Malaysia',
    population: 32700000,
    publishersPeak: 5637,
    ratio: 5849,
    publishersAverage: 5591,
    increase: null,
    baptized: 178,
    pioneersAverage: 1569,
    congregations: 121,
    studies: 4252,
    memorialAttendance: 12301,
  },
  {
    name: 'Mali',
    population: 21474000,
    publishersPeak: 365,
    ratio: 61530,
    publishersAverage: 349,
    increase: 1,
    baptized: 6,
    pioneersAverage: 63,
    congregations: 6,
    studies: 622,
    memorialAttendance: 982,
  },
  {
    name: 'Malta',
    population: 521000,
    publishersPeak: 874,
    ratio: 627,
    publishersAverage: 831,
    increase: 4,
    baptized: 11,
    pioneersAverage: 140,
    congregations: 11,
    studies: 277,
    memorialAttendance: 1368,
  },
  {
    name: 'Marshall Islands',
    population: 60000,
    publishersPeak: 147,
    ratio: 504,
    publishersAverage: 119,
    increase: 2,
    baptized: null,
    pioneersAverage: 30,
    congregations: 4,
    studies: 168,
    memorialAttendance: 708,
  },
  {
    name: 'Martinique',
    population: 368000,
    publishersPeak: 5050,
    ratio: 74,
    publishersAverage: 4945,
    increase: null,
    baptized: 87,
    pioneersAverage: 700,
    congregations: 57,
    studies: 3881,
    memorialAttendance: 11315,
  },
  {
    name: 'Mauritius',
    population: 1255000,
    publishersPeak: 2158,
    ratio: 597,
    publishersAverage: 2103,
    increase: 4,
    baptized: 52,
    pioneersAverage: 308,
    congregations: 27,
    studies: 2215,
    memorialAttendance: 5575,
  },
  {
    name: 'Mayotte',
    population: 326000,
    publishersPeak: 171,
    ratio: 2233,
    publishersAverage: 146,
    increase: -3,
    baptized: 3,
    pioneersAverage: 55,
    congregations: 3,
    studies: 185,
    memorialAttendance: 306,
  },
  {
    name: 'Mexico',
    population: 131563000,
    publishersPeak: 864689,
    ratio: 154,
    publishersAverage: 856224,
    increase: -1,
    baptized: 10832,
    pioneersAverage: 144181,
    congregations: 12881,
    studies: 479281,
    memorialAttendance: 2208399,
  },
  {
    name: 'Moldova',
    population: 3161000,
    publishersPeak: 18301,
    ratio: 174,
    publishersAverage: 18154,
    increase: -1,
    baptized: 424,
    pioneersAverage: 2551,
    congregations: 201,
    studies: 10650,
    memorialAttendance: 34957,
  },
  {
    name: 'Mongolia',
    population: 3373000,
    publishersPeak: 437,
    ratio: 7993,
    publishersAverage: 422,
    increase: -3,
    baptized: 11,
    pioneersAverage: 190,
    congregations: 8,
    studies: 855,
    memorialAttendance: 1365,
  },
  {
    name: 'Montenegro',
    population: 629000,
    publishersPeak: 341,
    ratio: 1918,
    publishersAverage: 328,
    increase: 2,
    baptized: 5,
    pioneersAverage: 97,
    congregations: 7,
    studies: 139,
    memorialAttendance: 884,
  },
  {
    name: 'Montserrat',
    population: 5000,
    publishersPeak: 36,
    ratio: 143,
    publishersAverage: 35,
    increase: -3,
    baptized: null,
    pioneersAverage: 14,
    congregations: 1,
    studies: 46,
    memorialAttendance: 132,
  },
  {
    name: 'Mozambique',
    population: 31616000,
    publishersPeak: 81719,
    ratio: 427,
    publishersAverage: 73993,
    increase: 7,
    baptized: 2728,
    pioneersAverage: 6854,
    congregations: 1551,
    studies: 87042,
    memorialAttendance: 286696,
  },
  {
    name: 'Myanmar',
    population: 55680000,
    publishersPeak: 5115,
    ratio: 10952,
    publishersAverage: 5084,
    increase: 1,
    baptized: 211,
    pioneersAverage: 902,
    congregations: 91,
    studies: 4339,
    memorialAttendance: 11670,
  },
  {
    name: 'Namibia',
    population: 2634000,
    publishersPeak: 2558,
    ratio: 1055,
    publishersAverage: 2496,
    increase: -2,
    baptized: 102,
    pioneersAverage: 320,
    congregations: 46,
    studies: 3372,
    memorialAttendance: 7539,
  },
  {
    name: 'Nauru',
    population: 11000,
    publishersPeak: 18,
    ratio: 688,
    publishersAverage: 16,
    increase: 7,
    baptized: null,
    pioneersAverage: 2,
    congregations: 1,
    studies: 9,
    memorialAttendance: 100,
  },
  {
    name: 'Nepal',
    population: 29192000,
    publishersPeak: 2823,
    ratio: 10415,
    publishersAverage: 2803,
    increase: 1,
    baptized: 96,
    pioneersAverage: 869,
    congregations: 43,
    studies: 2735,
    memorialAttendance: 7529,
  },
  {
    name: 'Netherlands',
    population: 17714000,
    publishersPeak: 29240,
    ratio: 609,
    publishersAverage: 29078,
    increase: 1,
    baptized: 340,
    pioneersAverage: 2688,
    congregations: 353,
    studies: 9175,
    memorialAttendance: 49218,
  },
  {
    name: 'Nevis',
    population: 14000,
    publishersPeak: 74,
    ratio: 197,
    publishersAverage: 71,
    increase: null,
    baptized: null,
    pioneersAverage: 5,
    congregations: 1,
    studies: 51,
    memorialAttendance: 249,
  },
  {
    name: 'New Caledonia',
    population: 271000,
    publishersPeak: 2636,
    ratio: 105,
    publishersAverage: 2573,
    increase: -1,
    baptized: 96,
    pioneersAverage: 317,
    congregations: 36,
    studies: 2436,
    memorialAttendance: 7265,
  },
  {
    name: 'New Zealand',
    population: 4898000,
    publishersPeak: 14669,
    ratio: 337,
    publishersAverage: 14542,
    increase: 1,
    baptized: 231,
    pioneersAverage: 1924,
    congregations: 170,
    studies: 5399,
    memorialAttendance: 26131,
  },
  {
    name: 'Nicaragua',
    population: 6779000,
    publishersPeak: 29326,
    ratio: 235,
    publishersAverage: 28857,
    increase: -1,
    baptized: 450,
    pioneersAverage: 4853,
    congregations: 471,
    studies: 19752,
    memorialAttendance: 81225,
  },
  {
    name: 'Niger',
    population: 26084000,
    publishersPeak: 346,
    ratio: 85521,
    publishersAverage: 305,
    increase: 7,
    baptized: 5,
    pioneersAverage: 44,
    congregations: 9,
    studies: 293,
    memorialAttendance: 804,
  },
  {
    name: 'Nigeria',
    population: 216747000,
    publishersPeak: 389961,
    ratio: 616,
    publishersAverage: 351732,
    increase: 8,
    baptized: 4521,
    pioneersAverage: 40227,
    congregations: 6081,
    studies: 347454,
    memorialAttendance: 742933,
  },
  {
    name: 'Niue',
    population: 2000,
    publishersPeak: 22,
    ratio: 100,
    publishersAverage: 20,
    increase: null,
    baptized: null,
    pioneersAverage: 3,
    congregations: 1,
    studies: 9,
    memorialAttendance: 74,
  },
  {
    name: 'North Macedonia',
    population: 1837000,
    publishersPeak: 1259,
    ratio: 1478,
    publishersAverage: 1243,
    increase: -1,
    baptized: 15,
    pioneersAverage: 201,
    congregations: 24,
    studies: 635,
    memorialAttendance: 2859,
  },
  {
    name: 'Norway',
    population: 5436000,
    publishersPeak: 11877,
    ratio: 461,
    publishersAverage: 11794,
    increase: null,
    baptized: 109,
    pioneersAverage: 1419,
    congregations: 163,
    studies: 3606,
    memorialAttendance: 17681,
  },
  {
    name: 'Pakistan',
    population: 229489000,
    publishersPeak: 1154,
    ratio: 209579,
    publishersAverage: 1095,
    increase: 3,
    baptized: 83,
    pioneersAverage: 62,
    congregations: 16,
    studies: 633,
    memorialAttendance: 3615,
  },
  {
    name: 'Palau',
    population: 18000,
    publishersPeak: 73,
    ratio: 269,
    publishersAverage: 67,
    increase: -8,
    baptized: null,
    pioneersAverage: 24,
    congregations: 2,
    studies: 113,
    memorialAttendance: 164,
  },
  {
    name: 'Palestinian Territories',
    population: 5599000,
    publishersPeak: 62,
    ratio: 91787,
    publishersAverage: 61,
    increase: 3,
    baptized: null,
    pioneersAverage: 12,
    congregations: 2,
    studies: 58,
    memorialAttendance: 131,
  },
  {
    name: 'Panama',
    population: 4447000,
    publishersPeak: 18414,
    ratio: 243,
    publishersAverage: 18291,
    increase: -1,
    baptized: 295,
    pioneersAverage: 3357,
    congregations: 317,
    studies: 12803,
    memorialAttendance: 57464,
  },
  {
    name: 'Papua New Guinea',
    population: 9292000,
    publishersPeak: 5197,
    ratio: 1987,
    publishersAverage: 4677,
    increase: -8,
    baptized: 131,
    pioneersAverage: 710,
    congregations: 91,
    studies: 5367,
    memorialAttendance: 35414,
  },
  {
    name: 'Paraguay',
    population: 7306000,
    publishersPeak: 11164,
    ratio: 664,
    publishersAverage: 10996,
    increase: -2,
    baptized: 271,
    pioneersAverage: 2273,
    congregations: 197,
    studies: 9226,
    memorialAttendance: 25946,
  },
  {
    name: 'Peru',
    population: 33397000,
    publishersPeak: 131346,
    ratio: 258,
    publishersAverage: 129198,
    increase: -1,
    baptized: 2499,
    pioneersAverage: 30457,
    congregations: 1551,
    studies: 133339,
    memorialAttendance: 420405,
  },
  {
    name: 'Philippines',
    population: 112509000,
    publishersPeak: 238609,
    ratio: 482,
    publishersAverage: 233488,
    increase: 2,
    baptized: 7228,
    pioneersAverage: 51854,
    congregations: 3511,
    studies: 159420,
    memorialAttendance: 664989,
  },
  {
    name: 'Pohnpei',
    population: 37000,
    publishersPeak: 60,
    ratio: 698,
    publishersAverage: 53,
    increase: -4,
    baptized: null,
    pioneersAverage: 17,
    congregations: 1,
    studies: 63,
    memorialAttendance: 138,
  },
  {
    name: 'Poland',
    population: 38180000,
    publishersPeak: 114673,
    ratio: 337,
    publishersAverage: 113276,
    increase: 1,
    baptized: 1054,
    pioneersAverage: 10200,
    congregations: 1272,
    studies: 27664,
    memorialAttendance: 191572,
  },
  {
    name: 'Portugal',
    population: 9858000,
    publishersPeak: 51334,
    ratio: 193,
    publishersAverage: 51116,
    increase: 1,
    baptized: 676,
    pioneersAverage: 6147,
    congregations: 656,
    studies: 17137,
    memorialAttendance: 86422,
  },
  {
    name: 'Puerto Rico',
    population: 2830000,
    publishersPeak: 23170,
    ratio: 123,
    publishersAverage: 22980,
    increase: -1,
    baptized: 238,
    pioneersAverage: 4009,
    congregations: 230,
    studies: 8390,
    memorialAttendance: 45579,
  },
  {
    name: 'Réunion',
    population: 974000,
    publishersPeak: 3468,
    ratio: 284,
    publishersAverage: 3426,
    increase: null,
    baptized: 77,
    pioneersAverage: 599,
    congregations: 41,
    studies: 1942,
    memorialAttendance: 6686,
  },
  {
    name: 'Rodrigues',
    population: 44000,
    publishersPeak: 70,
    ratio: 667,
    publishersAverage: 66,
    increase: 2,
    baptized: 2,
    pioneersAverage: 13,
    congregations: 1,
    studies: 78,
    memorialAttendance: 215,
  },
  {
    name: 'Romania',
    population: 19202000,
    publishersPeak: 39444,
    ratio: 488,
    publishersAverage: 39350,
    increase: null,
    baptized: 656,
    pioneersAverage: 5532,
    congregations: 533,
    studies: 19731,
    memorialAttendance: 79758,
  },
  {
    name: 'Rota',
    population: 3000,
    publishersPeak: 5,
    ratio: 750,
    publishersAverage: 4,
    increase: null,
    baptized: null,
    pioneersAverage: 2,
    congregations: null,
    studies: 2,
    memorialAttendance: 8,
  },
  {
    name: 'Rwanda',
    population: 13372000,
    publishersPeak: 31541,
    ratio: 449,
    publishersAverage: 29794,
    increase: 1,
    baptized: 1240,
    pioneersAverage: 5103,
    congregations: 562,
    studies: 33913,
    memorialAttendance: 76441,
  },
  {
    name: 'Saba',
    population: 2000,
    publishersPeak: 17,
    ratio: 154,
    publishersAverage: 13,
    increase: 8,
    baptized: null,
    pioneersAverage: 3,
    congregations: null,
    studies: 12,
    memorialAttendance: 71,
  },
  {
    name: 'St. Barthélemy',
    population: 11000,
    publishersPeak: 34,
    ratio: 344,
    publishersAverage: 32,
    increase: -9,
    baptized: null,
    pioneersAverage: 5,
    congregations: 1,
    studies: 20,
    memorialAttendance: 61,
  },
  {
    name: 'St. Eustatius',
    population: 3000,
    publishersPeak: 34,
    ratio: 111,
    publishersAverage: 27,
    increase: null,
    baptized: null,
    pioneersAverage: 5,
    congregations: 1,
    studies: 31,
    memorialAttendance: 61,
  },
  {
    name: 'St. Helena',
    population: 4000,
    publishersPeak: 116,
    ratio: 36,
    publishersAverage: 112,
    increase: 5,
    baptized: 2,
    pioneersAverage: null,
    congregations: 3,
    studies: 32,
    memorialAttendance: 283,
  },
  {
    name: 'St. Kitts',
    population: 40000,
    publishersPeak: 225,
    ratio: 183,
    publishersAverage: 219,
    increase: -1,
    baptized: 3,
    pioneersAverage: 30,
    congregations: 3,
    studies: 190,
    memorialAttendance: 721,
  },
  {
    name: 'St. Lucia',
    population: 185000,
    publishersPeak: 806,
    ratio: 237,
    publishersAverage: 780,
    increase: 1,
    baptized: null,
    pioneersAverage: 120,
    congregations: 11,
    studies: 776,
    memorialAttendance: 2334,
  },
  {
    name: 'St. Maarten',
    population: 44000,
    publishersPeak: 344,
    ratio: 134,
    publishersAverage: 328,
    increase: 1,
    baptized: 10,
    pioneersAverage: 42,
    congregations: 4,
    studies: 264,
    memorialAttendance: 1007,
  },
  {
    name: 'St. Martin',
    population: 32000,
    publishersPeak: 291,
    ratio: 116,
    publishersAverage: 277,
    increase: -1,
    baptized: 5,
    pioneersAverage: 30,
    congregations: 5,
    studies: 329,
    memorialAttendance: 844,
  },
  {
    name: 'St. Pierre and Miquelon',
    population: 6000,
    publishersPeak: 15,
    ratio: 400,
    publishersAverage: 15,
    increase: 7,
    baptized: null,
    pioneersAverage: 6,
    congregations: 1,
    studies: 4,
    memorialAttendance: 21,
  },
  {
    name: 'St. Vincent & the Grenadines',
    population: 112000,
    publishersPeak: 293,
    ratio: 394,
    publishersAverage: 284,
    increase: -2,
    baptized: 2,
    pioneersAverage: 46,
    congregations: 8,
    studies: 297,
    memorialAttendance: 958,
  },
  {
    name: 'Saipan',
    population: 48000,
    publishersPeak: 226,
    ratio: 219,
    publishersAverage: 219,
    increase: null,
    baptized: 4,
    pioneersAverage: 46,
    congregations: 3,
    studies: 227,
    memorialAttendance: 564,
  },
  {
    name: 'Samoa',
    population: 203000,
    publishersPeak: 545,
    ratio: 393,
    publishersAverage: 517,
    increase: -3,
    baptized: 16,
    pioneersAverage: 108,
    congregations: 12,
    studies: 542,
    memorialAttendance: 2748,
  },
  {
    name: 'San Marino',
    population: 34000,
    publishersPeak: 191,
    ratio: 180,
    publishersAverage: 189,
    increase: -1,
    baptized: null,
    pioneersAverage: 41,
    congregations: 2,
    studies: 83,
    memorialAttendance: 312,
  },
  {
    name: 'São Tomé and Príncipe',
    population: 227000,
    publishersPeak: 907,
    ratio: 254,
    publishersAverage: 895,
    increase: -2,
    baptized: 22,
    pioneersAverage: 153,
    congregations: 14,
    studies: 1763,
    memorialAttendance: 3532,
  },
  {
    name: 'Senegal',
    population: 17654000,
    publishersPeak: 1461,
    ratio: 12285,
    publishersAverage: 1437,
    increase: 2,
    baptized: 13,
    pioneersAverage: 192,
    congregations: 29,
    studies: 1802,
    memorialAttendance: 3051,
  },
  {
    name: 'Serbia',
    population: 6797000,
    publishersPeak: 3764,
    ratio: 1829,
    publishersAverage: 3716,
    increase: -2,
    baptized: 72,
    pioneersAverage: 700,
    congregations: 61,
    studies: 2152,
    memorialAttendance: 7254,
  },
  {
    name: 'Seychelles',
    population: 107000,
    publishersPeak: 371,
    ratio: 301,
    publishersAverage: 356,
    increase: -1,
    baptized: 16,
    pioneersAverage: 42,
    congregations: 5,
    studies: 480,
    memorialAttendance: 1181,
  },
  {
    name: 'Sierra Leone',
    population: 8306000,
    publishersPeak: 2525,
    ratio: 3721,
    publishersAverage: 2232,
    increase: -2,
    baptized: 79,
    pioneersAverage: 304,
    congregations: 41,
    studies: 3488,
    memorialAttendance: 7252,
  },
  {
    name: 'Slovakia',
    population: 5439000,
    publishersPeak: 11250,
    ratio: 488,
    publishersAverage: 11137,
    increase: null,
    baptized: 211,
    pioneersAverage: 1106,
    congregations: 133,
    studies: 3602,
    memorialAttendance: 23373,
  },
  {
    name: 'Slovenia',
    population: 2106000,
    publishersPeak: 1731,
    ratio: 1224,
    publishersAverage: 1721,
    increase: -1,
    baptized: 14,
    pioneersAverage: 254,
    congregations: 28,
    studies: 769,
    memorialAttendance: 2847,
  },
  {
    name: 'Solomon Islands',
    population: 721000,
    publishersPeak: 1631,
    ratio: 477,
    publishersAverage: 1510,
    increase: -1,
    baptized: 14,
    pioneersAverage: 186,
    congregations: 49,
    studies: 1027,
    memorialAttendance: 9317,
  },
  {
    name: 'South Africa',
    population: 60605000,
    publishersPeak: 97639,
    ratio: 629,
    publishersAverage: 96385,
    increase: null,
    baptized: 1953,
    pioneersAverage: 13591,
    congregations: 1999,
    studies: 69207,
    memorialAttendance: 228102,
  },
  {
    name: 'South Sudan',
    population: 11619000,
    publishersPeak: 1677,
    ratio: 7308,
    publishersAverage: 1590,
    increase: 6,
    baptized: 56,
    pioneersAverage: 216,
    congregations: 33,
    studies: 2083,
    memorialAttendance: 5289,
  },
  {
    name: 'Spain',
    population: 46719000,
    publishersPeak: 120614,
    ratio: 389,
    publishersAverage: 119965,
    increase: 1,
    baptized: 1847,
    pioneersAverage: 23736,
    congregations: 1416,
    studies: 37022,
    memorialAttendance: 198162,
  },
  {
    name: 'Sri Lanka',
    population: 22156000,
    publishersPeak: 7136,
    ratio: 3130,
    publishersAverage: 7078,
    increase: 1,
    baptized: 155,
    pioneersAverage: 1207,
    congregations: 100,
    studies: 7631,
    memorialAttendance: 16020,
  },
  {
    name: 'Sudan',
    population: 45992000,
    publishersPeak: 666,
    ratio: 72201,
    publishersAverage: 637,
    increase: -3,
    baptized: 26,
    pioneersAverage: 121,
    congregations: 13,
    studies: 839,
    memorialAttendance: 1836,
  },
  {
    name: 'Suriname',
    population: 592000,
    publishersPeak: 3649,
    ratio: 185,
    publishersAverage: 3194,
    increase: 2,
    baptized: 130,
    pioneersAverage: 377,
    congregations: 56,
    studies: 3426,
    memorialAttendance: 10209,
  },
  {
    name: 'Sweden',
    population: 10482000,
    publishersPeak: 22464,
    ratio: 469,
    publishersAverage: 22346,
    increase: null,
    baptized: 220,
    pioneersAverage: 2871,
    congregations: 281,
    studies: 7242,
    memorialAttendance: 34365,
  },
  {
    name: 'Switzerland',
    population: 8737000,
    publishersPeak: 19462,
    ratio: 451,
    publishersAverage: 19380,
    increase: null,
    baptized: 218,
    pioneersAverage: 2009,
    congregations: 258,
    studies: 6605,
    memorialAttendance: 31115,
  },
  {
    name: 'Tahiti',
    population: 280000,
    publishersPeak: 3251,
    ratio: 88,
    publishersAverage: 3171,
    increase: null,
    baptized: 44,
    pioneersAverage: 497,
    congregations: 45,
    studies: 2756,
    memorialAttendance: 10300,
  },
  {
    name: 'Taiwan',
    population: 23186000,
    publishersPeak: 11375,
    ratio: 2047,
    publishersAverage: 11325,
    increase: null,
    baptized: 299,
    pioneersAverage: 3924,
    congregations: 179,
    studies: 8620,
    memorialAttendance: 20804,
  },
  {
    name: 'Tanzania',
    population: 63299000,
    publishersPeak: 19998,
    ratio: 3365,
    publishersAverage: 18809,
    increase: 5,
    baptized: 547,
    pioneersAverage: 2137,
    congregations: 416,
    studies: 22384,
    memorialAttendance: 54088,
  },
  {
    name: 'Thailand',
    population: 70078000,
    publishersPeak: 5248,
    ratio: 13453,
    publishersAverage: 5209,
    increase: null,
    baptized: 153,
    pioneersAverage: 1932,
    congregations: 142,
    studies: 3592,
    memorialAttendance: 9548,
  },
  {
    name: 'Timor-Leste',
    population: 1369000,
    publishersPeak: 397,
    ratio: 3622,
    publishersAverage: 378,
    increase: 2,
    baptized: 13,
    pioneersAverage: 94,
    congregations: 5,
    studies: 442,
    memorialAttendance: 1152,
  },
  {
    name: 'Tinian',
    population: 3000,
    publishersPeak: 10,
    ratio: 300,
    publishersAverage: 10,
    increase: null,
    baptized: null,
    pioneersAverage: 1,
    congregations: 1,
    studies: 12,
    memorialAttendance: 30,
  },
  {
    name: 'Togo',
    population: 8681000,
    publishersPeak: 22500,
    ratio: 399,
    publishersAverage: 21780,
    increase: 2,
    baptized: 588,
    pioneersAverage: 2590,
    congregations: 349,
    studies: 26650,
    memorialAttendance: 54625,
  },
  {
    name: 'Tonga',
    population: 108000,
    publishersPeak: 211,
    ratio: 548,
    publishersAverage: 197,
    increase: 9,
    baptized: 5,
    pioneersAverage: 41,
    congregations: 3,
    studies: 216,
    memorialAttendance: 647,
  },
  {
    name: 'Trinidad & Tobago',
    population: 1407000,
    publishersPeak: 10623,
    ratio: 133,
    publishersAverage: 10557,
    increase: 1,
    baptized: 192,
    pioneersAverage: 1578,
    congregations: 130,
    studies: 8825,
    memorialAttendance: 27960,
  },
  {
    name: 'Turkey',
    population: 85562000,
    publishersPeak: 5438,
    ratio: 16132,
    publishersAverage: 5304,
    increase: 6,
    baptized: 110,
    pioneersAverage: 1928,
    congregations: 70,
    studies: 2546,
    memorialAttendance: 9244,
  },
  {
    name: 'Turks and Caicos',
    population: 40000,
    publishersPeak: 352,
    ratio: 125,
    publishersAverage: 319,
    increase: -5,
    baptized: null,
    pioneersAverage: 58,
    congregations: 7,
    studies: 296,
    memorialAttendance: 976,
  },
  {
    name: 'Tuvalu',
    population: 12000,
    publishersPeak: 97,
    ratio: 245,
    publishersAverage: 49,
    increase: -22,
    baptized: null,
    pioneersAverage: 3,
    congregations: 1,
    studies: 42,
    memorialAttendance: 210,
  },
  {
    name: 'Uganda',
    population: 44900000,
    publishersPeak: 9262,
    ratio: 4942,
    publishersAverage: 9085,
    increase: 1,
    baptized: 419,
    pioneersAverage: 1208,
    congregations: 167,
    studies: 17855,
    memorialAttendance: 28907,
  },
  {
    name: 'Ukraine',
    population: 41130000,
    publishersPeak: 127378,
    ratio: 335,
    publishersAverage: 122694,
    increase: -4,
    baptized: 1941,
    pioneersAverage: 18592,
    congregations: 1429,
    studies: 32037,
    memorialAttendance: 211528,
  },
  {
    name: 'United States of America',
    population: 334805000,
    publishersPeak: 1238431,
    ratio: 272,
    publishersAverage: 1230463,
    increase: -1,
    baptized: 16200,
    pioneersAverage: 229730,
    congregations: 12040,
    studies: 453281,
    memorialAttendance: 2344212,
  },
  {
    name: 'Uruguay',
    population: 3496000,
    publishersPeak: 11960,
    ratio: 294,
    publishersAverage: 11878,
    increase: -2,
    baptized: 193,
    pioneersAverage: 1885,
    congregations: 149,
    studies: 10564,
    memorialAttendance: 25010,
  },
  {
    name: 'Vanuatu',
    population: 322000,
    publishersPeak: 635,
    ratio: 530,
    publishersAverage: 607,
    increase: -15,
    baptized: 19,
    pioneersAverage: 99,
    congregations: 13,
    studies: 734,
    memorialAttendance: 3584,
  },
  {
    name: 'Venezuela',
    population: 28705000,
    publishersPeak: 131634,
    ratio: 221,
    publishersAverage: 130055,
    increase: -2,
    baptized: 2758,
    pioneersAverage: 31316,
    congregations: 1714,
    studies: 69536,
    memorialAttendance: 355695,
  },
  {
    name: 'Virgin Islands, British',
    population: 31000,
    publishersPeak: 218,
    ratio: 146,
    publishersAverage: 213,
    increase: -5,
    baptized: null,
    pioneersAverage: 30,
    congregations: 4,
    studies: 140,
    memorialAttendance: 765,
  },
  {
    name: 'Virgin Islands, U.S.',
    population: 104000,
    publishersPeak: 592,
    ratio: 200,
    publishersAverage: 521,
    increase: -2,
    baptized: 1,
    pioneersAverage: 108,
    congregations: 8,
    studies: 487,
    memorialAttendance: 1289,
  },
  {
    name: 'Wallis & Futuna Islands',
    population: 11000,
    publishersPeak: 66,
    ratio: 175,
    publishersAverage: 63,
    increase: null,
    baptized: 2,
    pioneersAverage: 6,
    congregations: 1,
    studies: 41,
    memorialAttendance: 212,
  },
  {
    name: 'Yap',
    population: 12000,
    publishersPeak: 34,
    ratio: 387,
    publishersAverage: 31,
    increase: null,
    baptized: 2,
    pioneersAverage: 7,
    congregations: 1,
    studies: 68,
    memorialAttendance: 132,
  },
  {
    name: 'Zambia',
    population: 18921000,
    publishersPeak: 215382,
    ratio: 99,
    publishersAverage: 191372,
    increase: 5,
    baptized: 4770,
    pioneersAverage: 18034,
    congregations: 3529,
    studies: 245959,
    memorialAttendance: 864131,
  },
  {
    name: 'Zimbabwe',
    population: 15179000,
    publishersPeak: 45582,
    ratio: 355,
    publishersAverage: 42810,
    increase: 2,
    baptized: 730,
    pioneersAverage: 7190,
    congregations: 1036,
    studies: 43528,
    memorialAttendance: 105824,
  },
];
